import React, { Component, ErrorInfo, PropsWithChildren } from 'react';
import * as sentry from 'utils/sentry';
import FallBackUI from './FallbackUI';

class ErrorBoundary extends Component<PropsWithChildren<any>, any> {
  constructor(props: PropsWithChildren<any>) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //log error
    if (process.env.NODE_ENV === 'development') return;
    sentry.catchErrorsWithScope(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <FallBackUI />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
